import React from 'react'

import Audits from '../components/views/audits/audits'
import { LayoutLoggedIn } from '../components/views/layout'

export default function AuditsPage() {
   return (
      <LayoutLoggedIn>
         <Audits />
      </LayoutLoggedIn>
   )
}
