import { renderDateOrNothing } from '../../../common/utils/functions'
import { PATH } from '../../../constants/global'
import { TABLE, CRUD } from '../../../constants/tables'
import { ToolStatus } from '../../../types'

export const AUDIT_TABLE = {
   downloadPath: PATH.AUDITS.DOWNLOAD,
   downloadHistoryPath: PATH.AUDITS.DOWNLOAD_AUDIT_ITEMS,
   extraClassName: 'table-actions',
   params: '',
   modalTitle: ' Audit',
   placeholder: ' audits',
   addButtonText: 'Create Audit',
   modalAddTitle: 'Title',
   key: 'AuditRecords',
   defaultActiveValue: {
      id: '',
      title: '',
      sendNotification: false,
      DueDateReminder: true,
   },
   defaultActionState: 0,
   objectKey: 'title',
   objectKeyTitleCase: 'Title',
   objectKeyNotificationCase: 'SendNotification',
   primaryActionText: CRUD.primaryActionText,
   secondaryActionText: CRUD.secondaryActionText,
   primaryDeleteText: CRUD.primaryDeleteText,
   crudPaths: PATH.AUDITS,
   tableTitle: TABLE.TITLE.AUDITS,
   header: 'Title',
   toggleTittle: 'Creation Notification',

   downloadRequest: {
      skip: 0,
      take: 10,
      query: null,
   },
   downloadCsvText: 'Export to Excel',
   exportFileName: 'Audits.xlsx',
   exportItemsFileName: 'AuditItems_Audit',
   radioToolStatus: [
      { label: 'All', status: ToolStatus.ALL },
      { label: 'Available', status: ToolStatus.AVAILABLE },
      { label: 'Pending', status: ToolStatus.PENDING },
      { label: 'Loaned', status: ToolStatus.LOANED },
      { label: 'Unavailable', status: ToolStatus.UNAVAILABLE },
   ],
   columnHeaders: [
      {
         Header: 'Actions',
         accessor: 'AuditID',
         minWidth: 80,
         align: 'center',
         Cell: (cell) => cell,
      },
      {
         Header: 'Audit Title',
         accessor: 'Title',
         width: 150,
         minWidth: 20,
      },
      {
         Header: 'Due Date',
         accessor: 'DateDue',
         align: 'center',
         width: 110,
         minWidth: 20,
         Cell: renderDateOrNothing,
      },
      {
         Header: 'Status',
         accessor: 'str_Status',
         align: 'center',
         width: 130,
         minWidth: 20,
      },
      {
         Header: 'Date Sent',
         accessor: 'DateSent',
         align: 'center',
         width: 110,
         minWidth: 20,
         Cell: renderDateOrNothing,
      },
      {
         Header: 'Audited By',
         accessor: 'AuditedBy',
         width: 200,
         minWidth: 20,
      },
      {
         Header: 'Date Received',
         accessor: 'DateReceived',
         align: 'center',
         width: 110,
         minWidth: 20,
         Cell: renderDateOrNothing,
      },
      {
         Header: 'Items Total',
         accessor: 'ItemTotal',
         width: 100,
         minWidth: 75,
         align: 'center',
      },
      {
         Header: 'Items Unconfirmed',
         accessor: 'ItemsUnConfirmed',
         width: 100,
         minWidth: 75,
         align: 'center',
      },
      {
         Header: 'Items Confirmed',
         accessor: 'ItemsConfirmed',
         width: 100,
         minWidth: 75,
         align: 'center',
      },
      {
         Header: 'Items Found',
         accessor: 'ItemsNotFoundInAudit',
         width: 100,
         minWidth: 75,
         align: 'center',
      },
      {
         Header: 'Date Reviewed',
         accessor: 'DateReviewed',
         align: 'center',
         width: 110,
         minWidth: 20,
         Cell: renderDateOrNothing,
      },
   ],
}
